// This is invoked after getCurrentUser
import { USER_PREFERENCES_ACTIONS } from "./userPreferences.actionTypes";

export const userPreferencesInitialize = userPreferences => ({
    type: USER_PREFERENCES_ACTIONS.INITIALIZE_USER_PREFERENCES,
    data: { userPreferences },
});

export const appSetOSMLayersWithCategories = osmLayersCategories => ({
    type: USER_PREFERENCES_ACTIONS.APP_OSM_LAYERS_CATEGORIES,
    data: { osmLayersCategories },
});

export const setAutoRefresh = autoRefreshEnabled => ({
    type: USER_PREFERENCES_ACTIONS.SET_AUTO_REFRESH,
    data: { autoRefreshEnabled },
});

export const setAutoApplyFilter = autoApplyFilter => ({
    type: USER_PREFERENCES_ACTIONS.SET_AUTO_APPLY_FILTER,
    data: { autoApplyFilter },
});

export const setAutoDismissMessages = autoDismissMessages => ({
    type: USER_PREFERENCES_ACTIONS.SET_AUTO_DISMISS_MESSAGES,
    data: { autoDismissMessages },
});

export const setEmailNotificationSettings = emailNotificationSettings => ({
    type: USER_PREFERENCES_ACTIONS.SET_EMAIL_NOTIFICATION_SETTINGS,
    data: { emailNotificationSettings },
});

export const setVectorMapSettings = vectorMapSettings => ({
    type: USER_PREFERENCES_ACTIONS.SET_VECTOR_MAP_SETTINGS,
    data: { vectorMapSettings },
});

export const setPagination = ({ pagination, section }) => ({
    type: USER_PREFERENCES_ACTIONS.SET_PAGINATION,
    data: { pagination, section },
});

export const setFiltered = ({ filtered, section }) => ({
    type: USER_PREFERENCES_ACTIONS.SET_FILTERED,
    data: { filtered, section },
});

export const setSorting = ({ sorting, section }) => ({
    type: USER_PREFERENCES_ACTIONS.SET_SORTING,
    data: { sorting, section },
});

export const setColumnVisibility = ({ columnVisibility, section }) => ({
    type: USER_PREFERENCES_ACTIONS.SET_COLUMN_VISIBILITY,
    data: { columnVisibility, section },
});

export const setTheme = theme => ({
    type: USER_PREFERENCES_ACTIONS.SET_THEME,
    data: { theme },
});

export const changeTheme = () => ({
    type: USER_PREFERENCES_ACTIONS.CHANGE_THEME,
});

export const setMapStyle = mapStyle => ({
    type: USER_PREFERENCES_ACTIONS.SET_MAP_STYLE,
    data: { mapStyle },
});

export const setCountry = country => ({
    type: USER_PREFERENCES_ACTIONS.SET_COUNTRY,
    data: { country },
});

export const setPreferredWeekdayType = weekdayType => ({
    type: USER_PREFERENCES_ACTIONS.SET_WEEKDAY_TYPE,
    data: { weekdayType },
});

export const setNavOpen = isOpen => ({
    type: USER_PREFERENCES_ACTIONS.SET_NAV_OPEN,
    data: { isOpen },
});

export const patchUserPreferences = ({ section, ...update }) => ({
    type: USER_PREFERENCES_ACTIONS.PATCH_USER_PREFERENCES,
    data: { update, section },
});

export const patchSessionUserPreferences = ({ section, ...update }) => ({
    type: USER_PREFERENCES_ACTIONS.PATCH_SESSION_USER_PREFERENCES,
    data: { update, section },
});

export const patchMapLayersSettings = ({ section, ...update }) => ({
    type: USER_PREFERENCES_ACTIONS.PATCH_MAP_LAYERS_SETTINGS,
    data: { update, section },
});
